import React, { Component } from "react";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";

import ConfirmationModal from "components/Modal/confirmation";

import { login } from "actions/login";
import { getAllDictionaryData } from "actions/dictionary";
import { createNewUser } from "actions/registration";

import { Post, Get } from "utils/axios";
import { getTranslation } from "../assets";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      bodyContentType: 0,
      showAssignButton: false,
      validReferralCode: false,

      onLoadEmailAvailability: false,
      onLoadMobileAvailability: false,
      onLoadNRICAvailability: false,

      statusModalMessage: "",
      emailAvailability: false,
      mobileAvailability: false,
      nricAvailability: false,
      confirmedEmailVerification: true,

      emailAvailabilityMessage: "",
      mobileAvailabilityMessage: "",
      firstNameVerificationErrorMessage: "",
      lastNameVerificationErrorMessage: "",
      nricVerificationMessage: "",
      confirmedEmailVerificationMessage: "",
      confirmedEmail: "",

      is_request_tac: false,
      is_request_otp: false,
      prevRegistrationId: this.props.data.registerReducer.newUser.message.id,

      countryMobile: 60,
      createNewAgent: {
        first_name: "",
        last_name: "",
        mobile_contact_number: "",
        country_id: null,
        identification_number: "",
        email: "",
        phone_otp: "",
        email_tac: "",
        team_id: 0,
        branch_id: null,
        board_registration_type_id: "none",
        board_registration_number: "",
        is_signed_nda_form: false,
        agent_registration_attributes: {
          direct_leader_id: "",
          notify_resignation: false,
          via_recruitment_campaign: false,
        },
        name_card_attributes: {
          name_card_display_name: "",
        },
      },
      nameSequence: null,
      from: "",

      showConfirmationModal: false,
      showConfirmationModalTitle: "",
      showConfirmationModalMode: "",
      showConfirmationModalMessage: "",
      showEducationLevelIncompeteModal: false,
      onClickConfirmationModalButton: () => {},
    };

    componentDidMount = () => {
      this.debounceEmailService = _.debounce(this.debounceEmailService, 1500);
      this.debounceMobileService = _.debounce(this.debounceMobileService, 1500);
      this.debounceFirstName = _.debounce(this.debounceFirstName, 200);
      this.debounceLastName = _.debounce(this.debounceLastName, 200);
      this.debounceConfirmedEmail = _.debounce(
        this.debounceConfirmedEmail,
        600
      );
      this.debounceNRIC = _.debounce(this.debounceNRIC, 600);
    };

    componentDidUpdate = () => {
      const { ajaxStatusReducer } = this.props.data;
      const {
        id,
        message,
        email,
        mobile_contact_number,
        payment_status,
        agent_status_id,
      } = this.props.data.registerReducer.newUser.message;
      const language = this.state.createNewAgent?.is_japan_team ? "jp" : "en";

      const tmpPath =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.adminSignInReducer.prefix_path
          : "";

      if (
        this.state.from === "CREATE_USER" &&
        ajaxStatusReducer.ajaxCallProgress === 0
      ) {
        if (
          ajaxStatusReducer.ajaxSuccess &&
          id &&
          id !== this.state.prevRegistrationId
        ) {
          this.setState({
            loading: false,
            from: "",

            showConfirmationModal: true,
            showConfirmationModalTitle: getTranslation("agent.signup.registration_successful", language),
            showConfirmationModalMode: "check",
            showConfirmationModalMessage: message,
            onClickConfirmationModalButton: () => {
              this.setState({ showConfirmationModal: false });
              if (agent_status_id === 0) {
                this.props.history.push({
                  pathname: `${tmpPath}/register/register-payment/`,
                  state: {
                    user_id: id,
                    selectedUserInfo: {
                      email: email,
                      mobile_contact_number: mobile_contact_number,
                      payment_status: payment_status,
                    },
                    from: "",
                  },
                });
              } else if (agent_status_id === 1) {
                this.props.history.push(tmpPath || "/");
              }
            },
          });
        }
        if (this.props.data.ajaxStatusReducer.ajaxError === true) {
          this.setState({
            loading: false,
            from: "",

            showConfirmationModal: true,
            showConfirmationModalTitle: getTranslation("agent.signup.registration_invalid", language),
            showConfirmationModalMode: "alert",
            showConfirmationModalMessage:
              this.props.data.ajaxStatusReducer.ajaxErrorMessage,
            onClickConfirmationModalButton: () => {
              this.setState({ showConfirmationModal: false });
            },
          });
        }
      }
    };

    // handle loading state
    load = (param) => this.setState({ loading: param });
    emailLoad = (param) => this.setState({ onLoadEmailAvailability: param });
    mobileLoad = (param) => this.setState({ onLoadMobileAvailability: param });
    nricLoad = (param) => this.setState({ onLoadNRICAvailability: param });

    // handle state and data changing
    onChangeHOC = (val, context) => this.setState({ [context]: val });

    onChangeConfirmedEmail = (val) => {
      let temp = val.replace(/\s/g, "");
      this.setState({ confirmedEmail: temp }, () => {
        this.debounceConfirmedEmail(temp);
      });
    };

    onChangeNRIC = (val) => {
      let temp = _.cloneDeep(this.state.createNewAgent);

      temp.identification_number = val.replace(/(^\s+|\s+$)/g, "");
      this.setState(
        { createNewAgent: temp, nricVerificationMessage: "" },
        () => {
          this.debounceNRIC(val);
        }
      );
    };

    onChangeField = (val, stateName) => {
      let tmp = _.cloneDeep(this.state.createNewAgent);
      tmp[stateName] = val;
      return this.setState({ createNewAgent: tmp });
    };

    onChangeCountryId = (val) => {
      let tmp = _.cloneDeep(this.state.createNewAgent);
      let tmpCountryMobile = _.find(this.props.countryOptions, {
        id: val,
      }).code;

      tmp.branch_id = null;
      tmp.country_id = val;

      this.setState(
        {
          countryMobile: tmpCountryMobile,
          createNewAgent: tmp,
        },
        () => {
          tmp.identification_number.length > 0 &&
            this.verifyPersonalInfo(tmp.identification_number, 3);
          tmp.mobile_contact_number &&
            this.verifyPersonalInfo(tmp.mobile_contact_number, 2);
        }
      );
    };

    onChangeLicenseType = (val) => {
      let tmp = _.cloneDeep(this.state.createNewAgent);
      tmp["board_registration_type_id"] = val;
      if (val === 0) {
        tmp["board_registration_number"] = "";
      }
      return this.setState({ createNewAgent: tmp });
    };

    onChangeEmailAddress = (val) => {
      let tmp = _.cloneDeep(this.state.createNewAgent);

      let tempEmail = val.replace(/\s/g, "");
      tmp.email = tempEmail;
      this.setState(
        {
          createNewAgent: tmp,
          emailAvailability: false,
          emailAvailabilityMessage: "",
        },
        () => {
          this.debounceEmailService(tempEmail);
        }
      );
    };

    onChangeMobileContact = (val) => {
      let tmp = _.cloneDeep(this.state.createNewAgent);
      let tempMobile = val.replace(/(^\s+|\s+$)/g, "");
      tmp.mobile_contact_number = tempMobile;
      this.setState(
        {
          createNewAgent: tmp,
          mobileAvailability: false,
          mobileAvailabilityMessage: "",
        },
        () => {
          this.debounceMobileService(val);
        }
      );
    };

    onChangeFirstName = (val) => {
      let tmp = _.cloneDeep(this.state.createNewAgent);
      tmp.first_name = val;
      tmp.name_card_attributes.name_card_display_name = val;

      this.setState({ createNewAgent: tmp }, () => {
        this.debounceFirstName(val);
      });
    };

    onChangeLastName = (val) => {
      let tmp = _.cloneDeep(this.state.createNewAgent);
      tmp.last_name = val;
      this.setState({ createNewAgent: tmp }, () => this.debounceLastName(val));
    };

    //debounce
    debounceNRIC = (val) => this.verifyPersonalInfo(val, 3);

    debounceConfirmedEmail = (val) =>
      this.setState({
        confirmedEmailVerification: this.state.createNewAgent.email === val,
        confirmedEmailVerificationMessage:
          this.state.createNewAgent.email === val
            ? getTranslation("agent.signup.matched_email", this.state.createNewAgent?.is_japan_team ? "jp" : "en")
            : getTranslation("agent.signup.not_matched_email", this.state.createNewAgent?.is_japan_team ? "jp" : "en"),
      });

    debounceEmailService = (val) => {
      this.verifyPersonalInfo(val, 1);
      this.setState({
        confirmedEmailVerification: this.state.confirmedEmail === val,
        confirmedEmailVerificationMessage:
          this.state.confirmedEmail === val
          ? getTranslation("agent.signup.matched_email", this.state.createNewAgent?.is_japan_team ? "jp" : "en")
          : getTranslation("agent.signup.not_matched_email", this.state.createNewAgent?.is_japan_team ? "jp" : "en"),
      });
    };

    debounceMobileService = (val) => this.verifyPersonalInfo(val, 2);

    debounceFirstName = (val) =>
      this.setState({
        firstNameVerificationErrorMessage: /\d/.test(val)
          ? getTranslation("agent.signup.first_name_error", this.state.createNewAgent?.is_japan_team ? "jp" : "en")
          : "",
      });

    debounceLastName = (val) =>
      this.setState({
        lastNameVerificationErrorMessage: /\d/.test(val)
          ? getTranslation("agent.signup.last_name_error", this.state.createNewAgent?.is_japan_team ? "jp" : "en")
          : "",
      });

    // verify mobile, email and nric
    verifyPersonalInfo = (val, type) => {
      let tmp = { subject: val, type_id: type };

      if (type === 1) {
        return Post(
          `/registrations/availability`,
          {
            ...tmp,
            country_id: this.state.createNewAgent.country_id,
          },
          this.verifyEmailExistSuccess,
          this.verifyEmailExistError,
          this.emailLoad
        );
      }
      if (type === 2) {
        val === ""
          ? (tmp.subject = "")
          : (tmp.subject = this.state.countryMobile + val);

        return Post(
          `/registrations/availability`,
          {
            ...tmp,
            country_id: this.state.createNewAgent.country_id,
          },
          this.verifyMobileExistSuccess,
          this.verifyMobileExistError,
          this.mobileLoad
        );
      } else {
        return Post(
          `/registrations/availability`,
          {
            ...tmp,
            country_id: this.state.createNewAgent.country_id,
          },
          this.verifyNRICExistSuccess,
          this.verifyNRICExistError,
          this.nricLoad
        );
      }
    };

    verifyEmailExistSuccess = (payload) =>
      this.setState({
        emailAvailability: true,
        emailAvailabilityMessage: ReactHtmlParser(payload.message),
      });
    verifyEmailExistError = (error) =>
      this.setState({
        emailAvailability: false,
        emailAvailabilityMessage: ReactHtmlParser(error),
      });

    verifyMobileExistSuccess = (payload) =>
      this.setState({
        mobileAvailability: true,
        mobileAvailabilityMessage: ReactHtmlParser(payload.message),
      });
    verifyMobileExistError = (error) =>
      this.setState({
        mobileAvailability: false,
        mobileAvailabilityMessage: ReactHtmlParser(error),
      });

    verifyNRICExistSuccess = (payload) =>
      this.setState({
        nricAvailability: true,
        nricVerificationMessage: payload && payload.message,
      });
    verifyNRICExistError = (error) =>
      this.setState({
        nricAvailability: false,
        nricVerificationMessage: error && ReactHtmlParser(error),
      });

    // handle TAC Field and submission of TAC
    onSubmitCreateAgent = () => {
      let tmp = _.cloneDeep(this.state.createNewAgent);
      let tmpFullName = "";
      let tempMobile =
        this.state.countryMobile +
        this.state.createNewAgent.mobile_contact_number;
      tmpFullName =
        this.state.nameSequence === "F+S"
          ? `${tmp.first_name + " " + tmp.last_name}`
          : `${tmp.last_name + " " + tmp.first_name}`;
      tmp.mobile_contact_number = tempMobile;
      tmp.first_name = tmp.first_name.trim();
      tmp.last_name = tmp.last_name.trim();
      tmp.full_name = tmpFullName;

      this.setState(
        {
          from: "CREATE_USER",
          loading: true,
        },
        () => this.props.createNewUser(tmp)
      );
    };

    //get direct leader id using referral code
    getReferalInfo = (val) => {
      Get(
        `/registrations/referrals/${val}`,
        this.getReferalInfoSuccess,
        this.getReferalInfoFailed,
        this.load
      );
    };
    getReferalInfoSuccess = (payload) => {
      const tempTeamId = payload.team_id;
      const tempDirectLeaderId = payload.id;

      const temp = _.cloneDeep(this.state.createNewAgent);
      temp.agent_registration_attributes.direct_leader_id = tempDirectLeaderId;
      temp.agent_registration_attributes.introducer_id = tempDirectLeaderId;
      temp.team_id = tempTeamId;
      temp.is_japan_team = payload.is_japan_team;

      this.props.onChangeDirectLeaderHOC(payload, "selectedDirectLeader");

      return this.setState({
        validReferralCode: true,
        createNewAgent: temp,
        showAssignButton: false,
      });
    };
    getReferalInfoFailed = () => this.setState({ validReferralCode: false });

    // Assign Direct leader
    onClickAssignDirectLeader = (param) => {
      let temp = _.cloneDeep(this.state.createNewAgent);
      temp.agent_registration_attributes.direct_leader_id = param.id;
      temp.team_id = param.team_id;
      this.setState({
        createNewAgent: temp,
        showAssignButton: false,

        showConfirmationModal: true,
        showConfirmationModalTitle: "Successful!",
        showConfirmationModalMode: "check",
        showConfirmationModalMessage: "Referrer assigned succesfully",
        onClickConfirmationModalButton: () => {
          this.setState({ showConfirmationModal: false });
        },
      });
    };

    onClickNextStep = () => {
      if (this.state.createNewAgent.is_malaysia_agent && !this.state.createNewAgent.is_spm_or_higher) {
        this.setState({ showEducationLevelIncompeteModal: true });
      } else {
        if (this.state.bodyContentType === 1) {
          this.props.getTACCode(this.state.createNewAgent.email);
          this.props.getOTPCode(
            this.state.countryMobile,
            this.state.createNewAgent.mobile_contact_number
          );
          this.props.onChangeOtpTacHOC(false, "mobileVerification");
          this.props.onChangeOtpTacHOC(false, "emailVerification");
        }
        this.setState({ bodyContentType: this.state.bodyContentType + 1 });
      }
    };

    onClickBackStep = () => {
      if (this.state.bodyContentType === 1) {
        let temp = _.cloneDeep(this.state.createNewAgent);
        temp.branch_id = "";
        temp.country_id = "";
        temp.identification_number = "";
        temp.mobile_contact_number = "";
        this.setState({ 
          createNewAgent: temp,
          nricVerificationMessage: "",
          mobileAvailabilityMessage: ""
        });

      } else if (this.state.bodyContentType === 2) {
        let temp = _.cloneDeep(this.state.createNewAgent);
        temp.phone_otp = "";
        temp.email_tac = "";
        
        this.props.stopTacCountDown();
        this.props.stopOtpCountDown();
        this.props.resetOtpCountDown();
        this.props.resetTacCountDown();
        this.setState({ createNewAgent: temp });
      }
      this.setState({ bodyContentType: this.state.bodyContentType - 1 });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadAgentRegister={this.state.loading}
            
            onChangeHOC={this.onChangeHOC}
            onChangeNRIC={this.onChangeNRIC}
            onChangeCountryId={this.onChangeCountryId}
            onChangeLicenseType={this.onChangeLicenseType}
            onSubmitCreateAgent={this.onSubmitCreateAgent}
            onChangeField={this.onChangeField}
            onChangeConfirmedEmail={this.onChangeConfirmedEmail}
            onChangeMobileContact={this.onChangeMobileContact}
            onChangeFirstName={this.onChangeFirstName}
            onChangeLastName={this.onChangeLastName}
            getReferalInfo={this.getReferalInfo}
            onClickNextStep={this.onClickNextStep}
            onClickBackStep={this.onClickBackStep}
            onChangeEmailAddress={this.onChangeEmailAddress}
            onClickAssignDirectLeader={this.onClickAssignDirectLeader}
          />

          <ConfirmationModal
            open={this.state.showConfirmationModal}
            title={this.state.showConfirmationModalTitle}
            message={this.state.showConfirmationModalMessage}
            mode={this.state.showConfirmationModalMode}
            positiveText={getTranslation("agent.signup.button_ok", this.state.createNewAgent?.is_japan_team ? "jp" : "en")}
            positiveAction={this.state.onClickConfirmationModalButton}
            hideNegative
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    getAllDictionaryData,
    login,
    createNewUser,
  })(WithHOC);
};

export default HOC;
