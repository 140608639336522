import React from "react";
import { connect } from "react-redux";
import { Card, CardContent, Grid } from "@material-ui/core";

import AtlasButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "../assets";

const profileData = [
  { value: "full_name" },
  { value: "email" },
  { value: "mobile_contact_number" },
  { value: "identification_number" },
  { value: "referrer_name" },
];

const WelcomeMessage = ({
  data,
  tmpProfileReducer,
  onClickNextModalSequence,
  sendLogoutRequest,
}) => {
  const language = tmpProfileReducer.country_id === 14 ? "jp" : "en";
  const verificationItems = tmpProfileReducer.country_id !== 1
    ? [getTranslation("agent.profile.verification_item", language)]
    : [
        "NRIC for verification purposes",
        "Bank account details to receive commission payments",
        "Highest academic qualification certificate for verification purpose (eg. SPM, A-level, bachelor's degree, master's degree)",
      ];
  
  return (
    <>
      <div class="welcome-header" style={{ marginTop: 44, marginBottom: 44 }}>
        <hr style={{ width: "100%" }} />
        <div className="welcome-stepper-icon-container">
          <div className="welcome-stepper-board">
            <AtlasIcon
              svgHref={"patch-exlamation-fill"}
              className={"welcome-stepper-atlas-icon"}
            />
          </div>
        </div>
      </div>

      <div className={"container py-4"}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={4}>
            <Card className="welcome-left-panel">
              <CardContent>
                <p
                  className={"text-center mb-4"}
                  style={{ fontSize: "24px", fontWeight: 600 }}
                >
                  {getTranslation("agent.profile.personal_info", language)}
                </p>
                <div className={"row"}>
                  {profileData.map((data) => (
                    <div key={data.value} className={"col-md-12 mb-3"}>
                      <p style={{ fontWeight: 500 }}>{getTranslation(data.value, language)}</p>
                      <p className="mt-3">
                        {tmpProfileReducer[data.value] || "N/A"}
                      </p>
                      <hr style={{ width: "100%" }} className="mt-3" />
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} className="welcome-right-panel-mobile">
            <p
              className={"text-center my-4"}
              style={{ fontSize: "24px", fontWeight: 600 }}>
              {getTranslation("agent.profile.almost_there", language)}
            </p>
            <p className="fs-3">{getTranslation("agent.profile.welcome_message", language)}</p>
            <br />
            <ol>
              {verificationItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AtlasButton
                className={"btn-new btn-new--primary"}
                style={{ marginTop: "40px" }}
                onClick={onClickNextModalSequence}
              >
                {getTranslation("next", language)}
              </AtlasButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(WelcomeMessage);
