import { Avatar, Card, CardContent, Tooltip } from "@material-ui/core";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { IoIosAddCircleOutline, IoMdDocument } from "react-icons/io";
import { compose } from "redux";

import AtlasButton from "components/Button";
import ToggleButton from "components/Button/toggle";
import CustomCard from "components/Card/projectCard";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import DocumentElement from "../document";
import EditProfileModal from "./edit";
import ViewModal from "./view";

import ExportERPHOC from "../../actions/exportErp";
import RecruitmentHOC from "../../actions/recruitment";
import UploadHOC from "../../actions/upload";

import CardBackground from "assets/images/card-bg.png";
import permissionsChecker from "utils/permissionsChecker";

const RENData = [
  { icon: "profile-circle", label: "Name", value: "full_name" },
  {
    icon: "personalcard",
    label: "ID/NRIC Number",
    value: "ren_identification_number",
  },
  { icon: "sms", label: "Email", value: "email" },
  { icon: "call", label: "Mobile Contact", value: "mobile_contact_number" },
  {
    icon: "home",
    label: "Current Address",
    value: "current_address",
    col: [8, 12],
  },
  { label: "Previous Company Name", value: "previous_company" },
  {
    label: "Job Title in Previous Company",
    value: "previous_company_job_title",
  },
  { label: "Bank Account Owner Name", value: "ren_bank_owner_name" },
  { label: "Bank Account", value: "ren_bank_account" },
  { label: "Emergency Person", value: "ren_emergency_person_name" },
  { label: "Emergency Contact", value: "ren_emergency_contact_number" },
  { label: "Name Card QR Code", value: "name_card_qr_code", type: "radio" },
  {
    label: "Name Card Profile Photo",
    value: "name_card_profile_photo",
    type: "radio",
  },
  { label: "Name Card Display Name", value: "name_card_display_name" },
  { label: "Via Recruitment Campaign", value: "via_recruitment_campaign" },
  {
    label: "Undischarged Bankruptcy",
    value: "is_undischarged_bankrupt_person",
    type: "radio",
  },
  {
    label: "Convicted Crime",
    value: "has_been_convicted_crime",
    type: "radio",
  },
];

const CardData = [
  { label: "REN Photo", value: "avatar" },
  { label: "IC Back", value: "ic_back" },
  { label: "IC Front", value: "ic_front" },
  { label: "Payment Proof", value: "payment_proof" },
  { label: "Bank Account Statement", value: "bank_account_statement" },
  { label: "SSM Copy", value: "ssm_copy" },
  { label: "Resignation Letter", value: "resignation_letter" },
  { label: "SPM or Higher Level Certification", value: "education_certificate" },
];

const FileInput = ({ onChange, can_update }) => {
  const filePond = useRef(null);

  return (
    <Tooltip
      placement={"top"}
      title={"Click to drop your file"}
      enterTouchDelay={50}
    >
      <div style={{ position: "relative" }}>
        {can_update && (
          <IoIosAddCircleOutline
            style={{
              cursor: "pointer",
              width: 28,
              color: "#FFFF",
              top: "7%",
              left: "42%",
              position: "absolute",
            }}
            onClick={() => filePond.current.click()}
          />
        )}
        <IoMdDocument style={{ height: 120, color: "#9aa7b3" }} />
        <input
          style={{ display: "none" }}
          type={"file"}
          ref={filePond}
          accept={"application/pdf, image/*"}
          onChange={(e) => onChange(e.target.files)}
        />
      </div>
    </Tooltip>
  );
};

const AtlasDetailsDialog = ({
  open,
  data,
  documentation,
  exportErp,

  onLoadUpload,
  onLoadRecruitment,
  onLoadERP,
  onLoadAdminApproval,

  detailData,
  onUploadImage,
  onToggleDialog,
  onChangeApprovalHOC,
  onSubmitRecruitment,
  getProofImage,
  resetToPending,
  showResetConfirmation,
  showRecruitmentConfirmation,
  showEditProfileModal,
  updatePendingNewREN,
}) => {
  const { id, agent_registration_id, status_id, export_status_id } = detailData;
  const { can_update } = permissionsChecker("New Agent Approvals", data);

  const [uploadDocs, onChangeDocs] = useState([]);
  const [viewFile, onChangeViewFile] = useState(null);
  const [showViewModal, onToggleView] = useState(false);

  useEffect(() => {
    open && getProofImage(agent_registration_id);
  }, [open]);

  useEffect(() => {
    documentation && onChangeDocs(documentation);
  }, [documentation]);

  const onChangeUploadDoc = useCallback(
    (context, fileItems) => {
      const tempDoc = _.cloneDeep(uploadDocs);

      if (fileItems) {
        Object.keys(fileItems).forEach((key) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            tempDoc[context] = {
              document: e.target.result,
              document_file_name: fileItems[key].name,
            };
            return onChangeDocs(tempDoc);
          };
          reader.readAsDataURL(fileItems[key]);
        });
      } else {
        tempDoc[context] = null;
        return onChangeDocs(tempDoc);
      }
    },
    [uploadDocs],
  );

  const processFile = useCallback((val) => {
    let temp = null;

    if (typeof val === "object") {
      temp = {
        document: val.document,
        type: val.document.indexOf("image") > -1 ? "image" : "pdf",
      };
    } else if (typeof val === "string") {
      temp = {
        document: val,
        type: val.indexOf("pdf") > -1 ? "pdf" : "image",
      };
    }

    return temp;
  }, []);

  const renderCardContent = () => {
    return (
      <>
        <Card
          className={"at-card--new mb-3"}
          style={{
            background: `#fff url('${CardBackground}') right top/auto no-repeat`,
          }}
        >
          <CardContent className={"p-3"}>
            <div className={"d-flex align-items-center mb-3"}>
              <h2 style={{ fontSize: 18, fontWeight: 600 }}>Profile</h2>
              {can_update && (
                <AtlasButton
                  className={"btn-new-sm btn-new--outline-secondary"}
                  style={{ fontSize: 14, marginLeft: 12 }}
                  onClick={() =>
                    onChangeApprovalHOC(true, "showEditProfileModal")
                  }
                >
                  <FiEdit
                    style={{
                      width: 16,
                      height: 16,
                      marginRight: 6,
                      color: "#3B82F6",
                    }}
                  />
                  Edit
                </AtlasButton>
              )}
            </div>
            <div className={"form-row"}>
              {RENData.slice(0, 5).map((field) => (
                <div
                  key={field.label}
                  className={`d-inline-flex align-items-center col-lg-${field.col?.[0] || 4} col-md-${field.col?.[1] || 6} mb-3`}
                >
                  <Avatar
                    style={{
                      width: 32,
                      height: 32,
                      backgroundColor: "#FFEDD5",
                    }}
                  >
                    <AtlasIcon
                      svgHref={`atlas-${field.icon}`}
                      style={{ width: 16, height: 16, fill: "#F0631D" }}
                    />
                  </Avatar>
                  <div
                    className={"ml-2"}
                    style={{ width: "calc(100% - 40px)" }}
                  >
                    <label className={"at-form-input__title"}>{field.label}</label>
                    <p className={"mw-100 text-truncate"}>
                      {detailData[field.value] || "N/A"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={
                "at-card--new new-agent-approval__additional-details-card"
              }
            >
              {RENData.slice(5).map((field) => (
                <div
                  key={field.label}
                  className={"new-agent-approval__additional-detail-item"}
                >
                  <label className={"at-form-input__title"}>{field.label}</label>
                  {field.value === "via_recruitment_campaign" && can_update && (
                    <div className={"d-flex"}>
                      <span>{detailData[field.value] || "N/A"}</span>
                      <ToggleButton
                        className={"ml-2"}
                        currentState={detailData[field.value] !== "Yes"}
                        onToggleButton={() =>
                          onChangeApprovalHOC(
                            true,
                            "showRecruitmentConfirmation",
                          )
                        }
                      />
                    </div>
                  )}
                  {field.value !== "via_recruitment_campaign" && (
                    <p className={"flex-fill"}>
                      {detailData[field.value] || "N/A"}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <CustomCard icon={"document"} title={"Attached Documents"}>
          <div className={"row"}>
            {CardData.map((data) => (
              <div
                key={data.value}
                className={"col-lg-2 col-md-3 col-sm-4 text-center mb-3"}
              >
                {uploadDocs[data.value] && (
                  <DocumentElement
                    fileData={processFile(uploadDocs[data.value])}
                    can_update={can_update}
                    isUnsaved={typeof uploadDocs[data.value] === "object"}
                    onRemoveDoc={() => onChangeUploadDoc(data.value, null)}
                    onClickOpenDoc={(val) => {
                      onChangeViewFile(val);
                      onToggleView(true);
                    }}
                  />
                )}
                {!uploadDocs[data.value] && (
                  <FileInput
                    can_update={can_update}
                    onChange={(val) => onChangeUploadDoc(data.value, val)}
                  />
                )}
                <p className={"mt-2 mb-3"} style={{ fontSize: "0.8em" }}>
                  {data.label}
                </p>
              </div>
            ))}
          </div>
          <div className="d-flex mt-20 g-3">
            {can_update && (
              <button
                className="btn-new btn-new--success"
                onClick={() => onUploadImage(agent_registration_id, uploadDocs)}
              >
                Upload Now
              </button>
            )}
          </div>
        </CustomCard>
        <div className={"mt-20"}>
          {status_id === 3 && can_update && (
            <button
              className={"btn-new-sm btn-new--secondary mr-10"}
              onClick={() => onChangeApprovalHOC(true, "showResetConfirmation")}
            >
              Reset to pending
            </button>
          )}
          {export_status_id === 2 && (
            <button
              className={"btn-new-sm btn-new--secondary"}
              onClick={() => exportErp(agent_registration_id)}
            >
              Export to ERP
            </button>
          )}
        </div>
        {(onLoadERP || onLoadAdminApproval || onLoadUpload) && <LoadingModal />}
      </>
    );
  };

  return (
    <>
      {showViewModal && (
        <ModalDialog
          title={"View Document"}
          fullWidth={true}
          fullHeight={true}
          onClose={() => onToggleView(false)}
          children={<ViewModal selectedFile={viewFile} />}
        />
      )}
      {showEditProfileModal && (
        <EditProfileModal
          renData={RENData}
          detailData={detailData}
          onLoadAdminApproval={onLoadAdminApproval}
          onClose={() => onChangeApprovalHOC(false, "showEditProfileModal")}
          updatePendingNewREN={updatePendingNewREN}
        />
      )}
      {open && (
        <ModalDialog
          title={"New Agent Approval Details"}
          fullWidth={true}
          fullHeight={true}
          onClose={() => onToggleDialog()}
          children={renderCardContent()}
          removeFooter={true}
        />
      )}
      <ConfirmationModal
        mode={"alert"}
        open={showResetConfirmation}
        message={"Are you sure to reset this record back to pending status?"}
        loading={onLoadAdminApproval}
        positiveAction={() => resetToPending(id)}
        negativeAction={() =>
          onChangeApprovalHOC(false, "showResetConfirmation")
        }
      />
      <ConfirmationModal
        mode={"alert"}
        open={showRecruitmentConfirmation}
        title={"Are you sure?"}
        message={"Click Yes to confirm to toggle the selection."}
        loading={onLoadRecruitment}
        positiveAction={() => onSubmitRecruitment(agent_registration_id, id)}
        negativeAction={() =>
          onChangeApprovalHOC(false, "showRecruitmentConfirmation")
        }
      />
    </>
  );
};

export default compose(
  RecruitmentHOC,
  ExportERPHOC,
  UploadHOC,
)(AtlasDetailsDialog);
