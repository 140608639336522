import { Card, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { useEffect } from "react";
import _ from "lodash";

import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import ConfirmationModal from "components/Modal/confirmation";
import FormInputs from "components/WelcomeModal/components/formInputs";

import { getTranslation } from "../assets";
import defaultAvatar from "assets/images/av-male.jpg";

import "../index.scss";

const TeamInfo = ({
  createNewAgent,
  onLoadAgentRegister,
  selectedDirectLeader,
  showEducationLevelIncompeteModal,

  onChangeHOC,
  onChangeField,
  onClickNextStep,
}) => {
  const { agent_registration_attributes, is_japan_team } = createNewAgent;
  const language = is_japan_team ? "jp" : "en";

  useEffect(() => {
    if (selectedDirectLeader && 
      (selectedDirectLeader.is_associated_agent || selectedDirectLeader.country_name !== "Malaysia")) {
      onChangeField(false, "is_malaysia_agent");
    }
  },[selectedDirectLeader]);

  const checkDisabled = () => {
    if(typeof createNewAgent.is_malaysia_agent === "boolean") {
      return createNewAgent.is_malaysia_agent
        ? typeof createNewAgent.is_spm_or_higher !== "boolean"
        : false
    }
    return true
  } 

  return (
    <div className={"container pt-4"} style={{ maxWidth: 768 }}>
      <div className={"mb-4"}>
        <p className={"fs-3"}>{getTranslation('agent.signup.paragraph1', language)}</p>
        <p className={"fs-3"}>{getTranslation('agent.signup.paragraph2', language)}</p>
      </div>
      {selectedDirectLeader && (
        <Card className={"p-10 mt-10 register-card"}>
          <div className={"row"}>
            <div className={"col-sm-3 mb-3 mb-sm-0"}>
              <img
                style={{
                  width: 130,
                  height: 130,
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                onError={(e) => {
                  if (e.target) {
                    e.target.src = defaultAvatar;
                  }
                }}
                src={selectedDirectLeader.avatar_url || defaultAvatar}
                alt={selectedDirectLeader.full_name}
              />
            </div>
            <div className={"col-sm-9"}>
              <div
                className={"d-flex flex-column justify-content-center"}
                style={{ height: "100%" }}
              >
                <Typography className="register-team-info-full-name">
                  {selectedDirectLeader.full_name}
                </Typography>
                <Typography className="register-team-info-email">
                  {selectedDirectLeader.email}
                </Typography>
                <Typography className="register-team-info-team">
                  {getTranslation('team', language)}:{" "}
                  <span className="register-team-info-highlight">
                    {selectedDirectLeader.team_name}
                  </span>
                </Typography>
              </div>
            </div>
          </div>
        </Card>
      )}
      {agent_registration_attributes.direct_leader_id && (
        <>
          <p className={"mb-4 pt-2 text-success"}>
            {selectedDirectLeader.full_name} {getTranslation('agent.signup.referral', language)}
          </p>
          {(!selectedDirectLeader.is_associated_agent && selectedDirectLeader.country_name === "Malaysia") && (
            <FormInputs
              payload={createNewAgent}
              inputConfig={[
                {
                  type: "radio",
                  label: getTranslation('agent.signup.malaysia_agent', language),
                  id: "is_malaysia_agent",
                  options: [
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ],
                },
                ...createNewAgent.is_malaysia_agent
                ? [{
                    type: "radio",
                    label: getTranslation('agent.signup.education_level', language),
                    id: "is_spm_or_higher",
                    options: [
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ],
                  }]
                : []
              ]}
              onChange={onChangeField}
            />
          )}
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className={"g-3 mt-4"}
      >
        <AtlasButton
          className={"btn-new btn-new--primary mt-4"}
          onClick={onClickNextStep}
          disabled={checkDisabled()} 
        >
          {getTranslation('next', language)}
        </AtlasButton>
      </div>
      <ConfirmationModal
        open={showEducationLevelIncompeteModal}
        title={'Not Eligible For Registration'}
        message={
          <span style={{ lineHeight: 1.5 }}>
            Effective from 1st October 2024, BOVAEA has imposed a new requirement mandating that all new Real Estate Negotiators possess a minimum academic qualification of SPM (or its equivalent) or higher. Check out the
            <a className="mx-1 fw-500" href="https://lppeh.gov.my/WP2016/notification-22-2024-new-requirements-to-get-certified-as-ren/" target="_blank" rel="noreferrer">circular</a>
            for more information.
          </span>
        }
        positiveText={'OK'}
        positiveAction={() => onChangeHOC(false, "showEducationLevelIncompeteModal")}
        hideNegative
      />
      {onLoadAgentRegister && <LoadingModal />}
    </div>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(TeamInfo);
