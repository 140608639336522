import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError } from "utils/requestHandler";

import { Get, Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      messages: [],
      loading: false,

      clearTypeBox: false,
    };

    load = (param) => this.setState({ loading: param });

    getMessages = (id) =>
      Get(
        `/sub_sale_claims/${id}/messages`,
        this.getMessagesSuccess,
        this.getMessagesError,
        this.load,
      );
    getMessagesSuccess = (payload) => {
      let temp = _.orderBy(payload, (o) => o.created_at, "asc");
      this.setState({ messages: temp, clearTypeBox: false });
    };
    getMessagesError = (error) => requestError(error);

    postMessage = (id, dataToSubmit) => {
      Post(
        `/sub_sale_claims/${id}/messages`,
        dataToSubmit,
        this.postMessageSuccess,
        this.postMessageError,
        this.load,
      );
    };
    postMessageSuccess = (payload) =>
      this.setState({ clearTypeBox: true }, () => {
        this.getMessages(payload.source_id);
      });
    postMessageError = (error) => {
      if(Array.isArray(error)) {
        error?.forEach((item) => requestError(item));
      } else {
        requestError(error)
      }
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            clearTypeBox={this.state.clearTypeBox}
            onLoadMessage={this.state.loading}
            messages={this.state.messages}
            getMessages={this.getMessages}
            postMessage={this.postMessage}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
